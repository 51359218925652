.date  {
  margin: 0px;
  padding: 0px 25px;
  text-align: right;
  width: 350px;
}

.title  {
  margin: 0px;
  padding: 0px 25px;
  text-align: left;
}

.drop:hover {
  color: #82A483;
}

.description  {
  text-align: left;
  padding-left: 450px;
}
