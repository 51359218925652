body {
  font: 14px "Century Gothic", Futura, sans-serif;
  margin: 20px;
  justifyContent: center;
  alignItems: center;
}

ol, ul {
  padding-left: 30px;
}

.board-row:after {
  clear: both;
  content: "";
  display: table;
}

.status {
  margin-bottom: 10px;
}

.card {
  background: #fff;
  border: 2px solid #000;
  float: left;
  font-size: 20px;
  font-weight: bold;
  line-height: 34px;
  height: 100px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 100px;
}

.card:focus {
  outline: none;
}

.kbd-navigation .card:focus {
  background: #ddd;
}

.board {
}

.hand {
}

.game {
  display: flex;
  flex-direction: row;
}

.game-info {
  margin-left: 20px;
  clear:left;
}

