/* Card Classes */
.big_card {
    padding: 0px;
    margin: 0px;
    border: 0px;

    height: 154px;
    width: 100px;

    display: inline-block;
    background-size: cover;
}

.big_card_text {
    padding: 2px 20px 8px 20px;
    margin: 121px 0px 0px 0px;
    border: 0px;

    height: 20px;
    width: 60px;

    font-size: 20px;
    color: lightgrey;
    text-align: right;
    
    background: rgba(0,0,0,0.5);
}

.small_card {
    padding: 0px;
    margin: 0px;
    border: 0px;

    height: 15px;
    width: 10px;

    display: inline-block;
    background-size: cover;
}

/* Player Display Classes */
.player_count_bar > * {
    vertical-align: middle;
}
