/* Pills */
.big_pill {
    padding: 5px;
    margin: 10px;

    min-width: 30px;

    display: inline-block;
    vertical-align: middle;
    border-radius: 40px;

    cursor: pointer;

    font-size: 14px !important;
    text-decoration: none;
}

.big_pill > * {
    padding: 5px;
    font-size: 14px !important;
    display: inline-block;
}

.small_pill {
    padding: 5px;
    margin: 2px;

    min-width: 30px;
    
    display: inline-block;
    vertical-align: middle;
    border-radius: 40px;

    cursor: pointer;

    font-size: 10px;
    text-decoration: none;
}

.small_pill > * {
    padding: 5px;
    display: inline-block;
}

.new_tag_small_pill {
    padding: 5px;
    margin: 2px;

    width: 19px;
    height: 19px;

    display: inline-block;
    vertical-align: middle;
    border-radius: 50%;

    cursor: pointer;

    text-align: center;
    font-size: 14px;
    text-decoration: none;

    background-color: #444444;
    color: lightgrey;
}

.tag_button_small_pill {
    padding: 5px;
    margin: 0px 0px 0px 5px;
    border: none;

    width: 19px;
    height: 19px;

    display: inline-block;
    vertical-align: middle;
    border-radius: 50%;

    cursor: pointer;

    text-align: center;
    font-size: 8px;
    text-decoration: none;

    background-color: rgba(128,128,128,0.5);
}



/* Tag Home Page Exclusive */
.new_tag_big_pill {
    padding: 5px;
    margin: 10px 10px;

    width: 19px;
    height: 19px;

    display: inline-block;
    vertical-align: middle;
    border-radius: 50%;

    cursor: pointer;

    text-align: center;
    font-size: 14px;
    text-decoration: none;

    background-color: #444444;
    color: lightgrey;
}


/* Tag Page Exclusive */
.tag_page_header {
    padding: 20px;
    margin: 0px;

    width: 100%;
    font-size: 30px;

    box-sizing: border-box;
}

.tag_page_description {
    padding: 20px;
    margin: 0px;

    width: 100%;
    font-size: 14px;
    box-sizing: border-box;
}
