html body {
    padding: 0px;
    border: none;
    margin: 0px;
}

.post_title {
    border: none;
    outline: none; 
    resize: none;
    width: 100%;
    height: 50px;
    font-size: 30px;
    clear: left;
    float: left;
}

.post_actions {
    padding: 0px 5px 0px 0px;
    color: #404040;
    font-size: 12px;
    text-decoration: none;
}

.post_body {
    border: none;
    outline: none; 
    resize: none;
    width: 100%;
    height: auto;
    font-size: 15px;
    clear: left;
    word-wrap: normal;
    overflow-wrap: normal;
}

.full_post {
    padding: 0px 10px;
    border: none;
    height: auto;
}

.post_border {
    padding: 0px;
    margin: 0px;
    border: none;
    background-image: linear-gradient(white,lightgrey);
    width: 100%;
    height: 20px;
}

.small_post {
    border-bottom: 1px solid grey;
    border-left: 1px solid grey;
    padding: 0px 5px;
    margin: 3px;
    color: black;
    text-decoration: none;
}

.post_list {
    overflow: auto;
    display: inline-block;
    width: 100%;
}

.post_link {
    padding: 0px;
    margin: 0px;
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.navigation {
    width: 100%;
}

.nav_button {
    padding: 5px 3px 6px 3px;
    border: 1px solid grey;
    margin: 0px;

    float: right;

    color: black;
    background: white;

    font-size: 12px;
    font-color: black;
    font: verdana;
    text-decoration: none;

    cursor: pointer;
}

.landing_button {
    padding: 5px 3px 6px 3px;
    border: 1px solid grey;
    margin: 0px;

    float: left;

    color: black;
    background: white;

    font-size: 12px;
    font-color: black;
    font: verdana;
    text-decoration: none;

    cursor: pointer;
}

.sign-in {
    height: 20px;
    width: 100%;
    text-align: center;
    padding: 5px;
}

.landing {
    width: 100%;
    height: 100%;

    background-image: url(https://firebasestorage.googleapis.com/v0/b/acramer-webhost.appspot.com/o/Milkyway.jpg?alt=media&token=927403ad-4c4d-4f5e-961e-c9b7c6063bf8);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;

    padding: 0px;
    margin: 0px;
    border: none;
}

.modal {
  font-size: 12px;
}
.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.modal > .content {
  width: 100%;
  padding: 10px 5px;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}
