.name_form {
    padding: 5px;
    margin: 10px;

    width: auto;
    height: 30px;

    border-radius: 40px;
    cursor: pointer;

    color: black;
    font-size: 30px;
    text-decoration: none;
}
