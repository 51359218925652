body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 20px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.landing_about {
    float: left;
    width: 50%;
    font-size: 24px;
    max-width: 500px;
    display: inline-box;
    line-height: 1.5
}

.landing_image {
    float: right;
    border-radius: 100000px;
    shape-outside: circle(100%);
    background-clip: content-box;
    width: 50%;
    max-width: 500px;
}

.landing_block {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
