body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 20px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.landing-section  {
  margin: 0px;
  padding: 50px;
}

.landing-dropdown {
  position: fixed;
  display: block;
  left: 10px;
  top: -5px;
}

.project-card {
  min-height: 300px;
  min-width: 400px;
  display: grid;
}

.project-content {
  margin: 22px 36px 71px 36px;

  grid-row:1;
  grid-column:1;

  z-index: 100;
}

.project-img {
  grid-row: 1;
  grid-column: 1;

  object-fit: cover;
  height: 300px;
  width: 400px;

  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.card-image-container {
  grid-row: 1;
  grid-column: 1;
  width: 100%;
  height: 600px;
  min-width: 400px;
  position: relative;
  overflow: hidden;
}

.card-image-container img {
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  top: -9999px;
  bottom: -9999px;
  left: -9999px;
  right: -9999px;
  margin: auto;
}

.project-selection {
  margin: 8px 8px;
  padding: 11px 25px 25px 25px;

  grid-row:1;
  grid-column:1;

  position: relative;

  border-radius: 4px;
}

.nav_button_link {
    margin: 0px;

    color: black;

    font-size: 16px;
    font-color: black;
    font: verdana;
    text-decoration: none;

    cursor: pointer;
    display: block;
}
